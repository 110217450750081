<template>
  <div class="achievement">
    <div style="height: 30px"></div>
    <div class="content" style="width: 1400px">
      <div class="title-left">
        <h2>荣誉资质</h2>
        <div style="height: 50px"></div>
        <div class="glance">
          <div v-for="(item, index) in glance_info" :key="index">
            <div class="title-data">
              <h2 style="font-size: 46px">
                {{ displayedNumbers[index] }}<span>{{ item.unit }}</span>
              </h2>
            </div>
            <h1 style="font-weight: 400; letter-spacing: 3px">
              {{ item.desc }}
            </h1>
          </div>
        </div>
      </div>
      <div class="certificate-right">
        <img :src="gxzs_img_url" alt="" width="350px" @dblclick="checkBigCertificate(gxzs_img_url)"/>
        <img
          v-for="(item, index) in certificate_info"
          :key="index"
          :src="item.img_url"
          alt=""
          width="200px"
          @dblclick="checkBigCertificate(item.img_url)"
        />
      </div>
    </div>

    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="760px"
    >
      <div style="display: flex; justify-content: center;">
        <img :src="big_certificate_url" alt="">
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      glance_info: [
        { number: '1', unit: '项', desc: '高新技术企业证书'},
        { number: '2', unit: '项', desc: '制定行业标准'},
        { number: '2', unit: '项', desc: '授权发明专利' },
        { number: '24', unit: '项', desc: '软件著作权' },
      ],
      displayedNumbers: [],
      gxzs_img_url: require("@/assets/images/home/certificate/gxzs1.png"),
      certificate_info: [
        { img_url: require("@/assets/images/home/certificate/hangyebiaozhun2.png") },
        { img_url: require("@/assets/images/home/certificate/hangyebiaozhun1.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu01.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu02.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu03.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu04.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu05.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu06.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu07.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu08.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu09.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu1010.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu1111.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu1212.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu1313.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu1414.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu1515.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu1616.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu1717.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu1818.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu1919.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu2020.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu2121.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu2222.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu2323.png") },
        { img_url: require("@/assets/images/home/certificate/ruanzhu2424.png") },
      ],
      dialogVisible: false,
      big_certificate_url: null,
    };
  },
  mounted() {
    this.startCounting()
  },
  methods: {
    startCounting() {
      this.displayedNumbers = new Array(this.glance_info.length).fill(0)
      const targetNumbers = this.glance_info.map(item => parseInt(item.number))
      let currentNumbers = new Array(this.glance_info.length).fill(0)

      const timer = setInterval(() => {
        let shouldClearTimer = true
        for (let i = 0; i < this.glance_info.length; i++) {
          if (currentNumbers[i] < targetNumbers[i]) {
            currentNumbers[i] += 1
            shouldClearTimer = false
          }
        }

        this.displayedNumbers = [...currentNumbers]

        if (shouldClearTimer) {
          clearInterval(timer)
        }
      },100)
    },
    checkBigCertificate(bigCertificateUrl) {
      this.dialogVisible = true
      this.big_certificate_url = bigCertificateUrl
    }
  }
};
</script>

<style lang="less" scoped>
.achievement {
  position: relative;
  width: 100%;
  height: 1750px;
  background: rgb(246, 246, 246);
  .title-left {
    width: 220px;
    height: 1400px;
    float: left;
    .glance h2{
        margin: 30px 0;
    }
  }
  .certificate-right img {
    margin-left: 30px;
  }
  img {
    cursor: pointer;
  }
}
</style>